import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step ssh logout`}</strong>{` -- removes a private key from the ssh-agent`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step ssh logout [<identity>] [--all]
[--identity=<identity>] [--offline] [--ca-config=<file>]
[--ca-url=<uri>] [--root=<file>] [--context=<name>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step ssh logout`}</strong>{` commands removes a key from the ssh-agent.`}</p>
    <p>{`By default it only removes certificate keys signed by step-certificates, but the
flag `}<strong parentName="p">{`--all`}</strong>{` can be used to remove all keys with a given subject or all keys.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`identity`}</inlineCode>{`
The certificate identity or comment in the key.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--all`}</strong>{`
Removes all the keys stored in the SSH agent.`}</p>
    <p><strong parentName="p">{`--identity`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
The certificate identity. It is usually passed as a positional argument, but a
flag exists so it can be configured in $STEPPATH/config/defaults.json.`}</p>
    <p><strong parentName="p">{`--offline`}</strong>{`
Creates a certificate without contacting the certificate authority. Offline mode
uses the configuration, certificates, and keys created with `}<strong parentName="p">{`step ca init`}</strong>{`,
but can accept a different configuration file using `}<strong parentName="p">{`--ca-config`}</strong>{` flag.`}</p>
    <p><strong parentName="p">{`--ca-config`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The certificate authority configuration `}<inlineCode parentName="p">{`file`}</inlineCode>{`. Defaults to
$(step path)/config/ca.json`}</p>
    <p><strong parentName="p">{`--ca-url`}</strong>{`=`}<inlineCode parentName="p">{`URI`}</inlineCode>{`
`}<inlineCode parentName="p">{`URI`}</inlineCode>{` of the targeted Step Certificate Authority.`}</p>
    <p><strong parentName="p">{`--root`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the PEM `}<inlineCode parentName="p">{`file`}</inlineCode>{` used as the root certificate authority.`}</p>
    <p><strong parentName="p">{`--context`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The context `}<inlineCode parentName="p">{`name`}</inlineCode>{` to apply for the given command.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Remove all identities signed by your SSH CA:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ssh logout
`}</code></pre>
    <p>{`Remove the certificate mariano@work from the SSH agent:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ssh logout mariano@work
`}</code></pre>
    <p>{`Remove the all the keys and certificates for mariano@work from the SSH agent:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ssh logout --all mariano@work
`}</code></pre>
    <p>{`Remove the key mariano@work from the agent listening in /tmp/ssh/agent:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ SSH_AUTH_SOCK=/tmp/ssh/agent step ssh logout mariano@work
`}</code></pre>
    <p>{`Remove all the keys stored in the SSH agent:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ssh logout --all
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      